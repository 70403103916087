.about-us-page .video-section {
  position: relative;
  width: 100%;
  height: auto;
}

.background-video {
  width: 100%;
  height: auto;
}

.story-section, .mission-section {
  text-align: center;
  margin: 50px 0;
}

.story-section h2, .mission-section h2 {
  margin-bottom: 20px;
}

.story-text {
  font-style: italic;
  max-width: 60%;
  margin: 0 auto; /* Centers the text block */
}

/* Add custom styles to fit your branding and design requirements */
.video-container {
  position: relative;
}
video{
  max-width: 100%;
  height: auto;
}

.body {
  background-color: black;
}
