.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* CSS styles for small screens */
@media screen and (max-width: 768px) {
  /* Styles for small screens */
  .App-logo {
    height: 30vmin; /* Decrease logo size for small screens */
  }

  .App-header {
    min-height: 80vh; /* Adjust header height for small screens */
  }

  body {
    font-size: 14px;
  }

  /* Add more styles for small screens */
}

/* CSS styles for medium screens */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  /* Styles for medium screens */
  body {
    font-size: 16px;
  }
  .App-header {
    background-size: cover; /* Adjust background size to cover */
  }

  /* Add more styles for medium screens */
}

/* CSS styles for large screens */
@media screen and (min-width: 1025px) {
  /* Styles for large screens */
  body {
    font-size: 18px;
  }
  .App-header {
    background-size: cover; /* Adjust background size to cover */
  }

  /* Add more styles for large screens */
}
