/* src/components/NFTCard.css */
.nft-card {
    border: 1px solid #e5f1b2;
    padding: 8px;
    margin: 8px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    user-select: none;
    position: relative;
  }
  
  .nft-card:hover {
    transform: scale(1.05);
  }
  
  .nft-card img {
    max-width: 100%;
    height: auto;
  }
  
  .nft-card div {
    text-align: center;
  }
  
  .nft-card h3 {
    margin-top: 10px;
  }
  

  
  .nft-card::before {
    content: 'ARTTROPS';
    position: absolute;
    top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.5); /* Transparent white text */
  font-size: 24px; /* Adjust font size as needed */
  font-weight: bold;
  pointer-events: none;
  }
  